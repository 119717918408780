<template>
  <div>
    <v-data-table :headers="headers" :items="skus" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Services</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <button
            type="button"
            class="mb-2 btn btn-info px-5 py-3 ls1"
            @click="showAddSkuModal()"
          >
            New Service
          </button>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <div>{{ getServiceName(item.id) }}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="showEditSkuModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="removeSkus(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <EditSku
      ref="editSku"
      :actionFunction="editSkus"
      :services="services"
      :pageLoader="pageLoader"
      :item="itemForEdit"
      :contract="contractId"
    ></EditSku>
    <AddSku
      ref="addSku"
      :actionFunction="addSkus"
      :services="services"
      :pageLoader="pageLoader"
      :contract="null"
    ></AddSku>
  </div>
</template>

<script>
import EditSku from "@/own/components/finance/customers/serviceContainer/EditSku";
import AddSku from "@/own/components/finance/customers/serviceContainer/AddSku";
import Swal from "sweetalert2";
export default {
  name: "SkuDatatable",
  props: ["setSku", "skus", "services", "pageLoader", "contractId"],
  components: { EditSku, AddSku },
  data: () => ({
    dialog: false,
    editItem: null,
    headers: [
      { text: "Id", value: "id" },
      { text: "Name", value: "name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  computed: {
    itemForEdit() {
      return this.editItem;
    },
  },
  methods: {
    getServiceName(id) {
      let name = "";
      this.services.forEach((service) => {
        if (parseInt(service.index) === parseInt(id)) {
          name = service.text;
        }
      });
      return name;
    },
    showEditSkuModal(item) {
      this.editItem = item;
      this.$nextTick(() => {
        this.$refs.editSku.toggleModal();
      });
    },
    showAddSkuModal() {
      this.$refs.addSku.toggleModal();
    },
    isRecordExist(item, items) {
      let double = [0, 0];
      items.forEach((element, index) => {
        if (parseInt(element.id) === parseInt(item.id)) {
          double = [1, index];
        }
      });
      return double;
    },
    editSkus(item) {
      let new_skus = [...this.skus];
      let double = this.isRecordExist(item, new_skus);
      if (double[0]) {
        new_skus[double[1]] = item;
        this.setSku(new_skus);
      } else {
        new_skus.push(item);
        this.setSku(new_skus);
      }
    },
    addSkus(items) {
      let new_skus = [...this.skus];
      items.forEach((it) => {
        let item = { id: it };
        let double = this.isRecordExist(item, new_skus);
        if (double[0]) {
          new_skus[double[1]] = item;
          this.setSku(new_skus);
        } else {
          new_skus.push(item);
        }
      });
      this.setSku(new_skus);
    },
    removeSkus(item) {
      let new_skus = [...this.skus];
      let double = this.isRecordExist(item, new_skus);
      if (double[0]) {
        new_skus.splice(double[1], 1);
        this.setSku(new_skus);
      } else {
        Swal.fire({
          title: "Error",
          text: `Something went wrong!`,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
  },
};
</script>
